var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"max-w-screen-lg px-2 py-20 mx-auto",attrs:{"id":"prices"}},[_c('div',{staticClass:"grid grid-cols-1 gap-20 md:grid-cols-2"},[_c('div',{staticClass:"md:order-2"},[_c('title-block',{staticClass:"text-center",scopedSlots:_vm._u([{key:"small",fn:function(){return [_vm._v("Gabba Doggy Daycare")]},proxy:true},{key:"big",fn:function(){return [_vm._v("Price List "),_c('br')]},proxy:true}])}),_c('p',{staticClass:"text-xs text-center"},[_vm._v("(as of 01/09/22)")]),_c('p',{staticClass:"mt-5 font-bold text-center"},[_vm._v("Casual Day Care")]),_c('div',{staticClass:"grid grid-cols-1 pt-5 text-sm gap-x-10"},_vm._l((_vm.prices[_vm.region].daycare),function(service,i){return _c('div',{key:i,staticClass:"mb-1 bg-white",class:{ 'font-bold': service.highlight }},[_c('div',{staticClass:"relative flex bg-gray-100 rounded-lg ring-pink-200",class:{ 'ring-2 ': service.highlight }},[(service.highlight)?_c('div',[_c('div',{staticClass:"absolute -left-1.5 top-2 w-2 h-2 bg-pink-500 rounded-full animate-ping-slow"}),_c('div',{staticClass:"absolute -left-1.5 top-2 w-2 h-2 bg-pink-500 rounded-full"})]):_vm._e(),_c('div',{staticClass:"flex-grow px-2"},[_vm._v(" "+_vm._s(service.name)+" "),(service.soon)?_c('span',{staticClass:"text-pink-500"},[_vm._v(_vm._s(service.soon))]):_vm._e(),(service.highlight)?_c('span',{staticClass:"text-pink-500"},[_vm._v("most popular")]):_vm._e()]),_c('div',{staticClass:"relative px-2"},[_c('span',{staticClass:"text-pink-500"},[_vm._v(_vm._s(service.price))])])])])}),0),(_vm.prices[_vm.region].extras)?_c('div',{attrs:{"id":"extras-pricing"}},[_c('p',{staticClass:"mt-5 font-bold text-center"},[_vm._v("Extras")]),_c('div',{staticClass:"grid grid-cols-1 pt-5 text-sm gap-x-10"},_vm._l((_vm.prices[_vm.region].extras),function(extra,i){return _c('div',{key:i,staticClass:"mb-2 bg-white"},[_c('div',{staticClass:"flex bg-gray-100 rounded-lg",class:{
                                'taxi cursor-pointer':
                                    extra.selector == 'taxi',
                            },on:{"click":_vm.showTaxi}},[_c('div',{staticClass:"flex-grow px-2",class:{
                                    'opacity-50':
                                        extra.soon == '- available soon',
                                }},[_vm._v(" "+_vm._s(extra.name)+" "),(extra.soon)?_c('span',{staticClass:"text-pink-500"},[_vm._v(_vm._s(extra.soon))]):_vm._e()]),_c('div',{staticClass:"px-2"},[_c('span',{staticClass:"text-pink-500"},[_vm._v(_vm._s(extra.price))])])])])}),0)]):_vm._e()],1),_c('div',{staticClass:"my-20 bg-center bg-cover rounded-lg shadow-xl md:order-1 aspect-w-3 aspect-h-4",staticStyle:{"background-image":"url('https://res.cloudinary.com/dg5ybbkbh/image/upload/c_scale,w_400/f_auto,q_auto/v1631854044/gddc/photos/update/0003.jpg')"}})]),_c('div',{staticClass:"flex flex-col gap-2 px-5 mt-10 text-sm"},[_c('p',{staticClass:"font-bold"},[_vm._v("Package Terms and Conditions:")]),_c('p',[_vm._v(" All packages are available to purchase online or in-store but must be purchased on or before the day of your appointment. If no package has been purchased by this time, the full casual rate will apply. To purchase online, simply "),_c('open-login',{staticClass:"link-pink"},[_vm._v("log in")]),_vm._v(" to your account and select "),_c('em',[_vm._v("Purchase a Package")]),_vm._v(". ")],1),_vm._m(0),_c('p',[_vm._v(" Please note that customers on recurring billing, or pre-purchased packages will be given priority placement over casual bookings. ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" If you plan on bringing your dog to daycare regularly, ask us about setting up automatic recurring payments so you don't have to worry about purchasing packages each week. If you would like to cancel, pause, or adjust automatic payments at any time, we're happy to accommodate "),_c('i',{staticClass:"fal fa-smile"})])
}]

export { render, staticRenderFns }